<template lang="pug">
  v-card
    v-card-title Редактирование получателя
    v-card-text
      v-row
        v-col(cols="3")
          p Контакт
          p(v-if="recipient.orgName") Организация
          p(v-if="recipient.hostName") Хостнейм
        v-col(cols="9")
          p {{recipient.contact}}
          p(v-if="recipient.orgName") {{recipient.orgName}}
          p(v-if="recipient.hostName") {{recipient.hostName}}
      v-row
        v-col(cols="3")
          p Типы нотификаций
        v-col(cols="9")
          SelectNotificationTypes(
            v-model="activeTypes"
            :items="types"
            :error-messages="getValidationErrors('validTypes')"
          )

      v-col.d-flex.justify-center
        v-btn.mr-3.text--secondary(
          text
          :disabled="loading"
          @click="$emit('close')"
        ) Отмена
        v-btn(
          :loading="loading"
          depressed
          color="primary"
          @click="save"
        ) Сохранить
</template>

<script>
import SelectNotificationTypes from '@/components/controls/SelectNotificationTypes.vue';
import mixins from '@/utils/mixins';
import Validate from '@/validations/notificationsEdit';
import { mapActions } from 'vuex';

export default mixins(Validate).extend({
  components: { SelectNotificationTypes },
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    recipient: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTypes: [],
      isContactEmail: false,
      isContactPhone: false,
      email: null,
      phone: null,
      scrollOptios: Object.freeze({
        suppressScrollX: true,
      }),
    };
  },

  mounted() {
    this.recipient.typeIds.active &&
      (this.activeTypes = this.recipient.typeIds.active);
  },

  computed: {
    // FIXME: not transparent validation at all
    validTypes: {
      get() {
        if (!this.activeTypes.length) {
          return false;
        }
        // checking that types changed
        const initValue = this.recipient.typeIds.active
          .sort((a, b) => a - b)
          .join();
        const newValue = this.activeTypes.sort((a, b) => a - b).join();
        return newValue !== initValue;
      },
    },
  },

  methods: {
    ...mapActions('NOTIFICATIONS', {
      edit: 'edit',
    }),

    async save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Ошибка. Выберите новые типы нотификаций`,
          text: '',
        });
        return null;
      }

      const query = { ...this.recipient };
      query.typeIds = this.activeTypes;
      await this.edit([query])
        .then(() => {
          this.$notify({
            group: 'note',
            type: 'info',
            title: `Изменения сохранены`,
            text: '',
          });
        })
        .catch(() => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: `Произошла ошибка сохранения получателя.`,
            text: '',
          });
        });
      this.$emit('update');
      this.$emit('close');
    },
  },
});
</script>
